.service-img-container {
  height: 180px;
  overflow: hidden;
}

.as_blog_box {
  /* height: 280px; */
  transition: transform 0.2s ease-in-out;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.9);
  background-color: #09273C;

}

.as_btn {
  cursor: pointer;
}

@media (max-width: 767px) {
  .service-img-container {
    /* height: 60vw; */
  }

  .as_blog_box {
    height: auto;
  }
}

.as_blog_box p {
  padding: 0 8px;
  padding-bottom: 8px;
}

.as_blog_box p span {
  cursor: pointer;
  user-select: none;
}

.as_blog_box h4 {
  padding: 0 8px;
}

.as_blog_box.read-more {
  z-index: 10;
}

.all-services {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 16px;
}