.as_customer_wrapper {
  background-image: url('../../assets/images/bg/testimonial-section-bg.jpg')
}

.testimonial-container {
  margin-top: 32px;
}

.as_customer_img img {
  max-width: 100px;
}

.as_customer_box:before {
  opacity: 0.5;
}