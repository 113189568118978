.next {
  margin-top: 16px;
}

.as_checkout_wrapper {
  background-image: url(../../assets/images/bg/testimonial-section-bg.jpg);
}

.payment-button {
  padding: 0;
  cursor: default;
}

.as_btn input {
  background-color: transparent;
  font-family: inherit;
  text-transform: uppercase;
  height: 100%;
  padding: 0 23px;
  border: none;
}

.serviceName-price {
  display: flex;
  justify-content: space-between;
}

.service-price {
  color: #1cb71c;
  margin-bottom: 16px;
}

#pay-later {
  margin-right: 32px;
}

.see-purohits {
  color: #97b0c1;
  font-style: italic;
}

.see-purohits a {
  /* text-decoration: underline; */
  color: var(--secondary-color);
}

.form-control[disabled] {
  background-color: rgba(39, 67, 85, 0.773);
  color: #97b0c1;
}

.upi-id,
.phonepe-logo {
  color: #111;
}

.phonepe-logo img {
  width: 100%;
  max-width: 300px;
  margin-top: 24px;
}

.phonepe-logo .accepted {
  color: #5f259f;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  margin-top: 18px;
}

.phonepe-logo .scan-pay {
  font-weight: 500;
  font-size: 18px;
  margin-top: 24px;
}

.qr-container {
  background-color: #fff;
  max-width: 550px;
  margin: 0 auto;
  padding-bottom: 24px;
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
}

.qr-code {
  margin: 12px 0;
  width: 100%;
  max-width: 350px;
}

@media (max-width: 540px) {
  .phonepe-logo img {
    width: 100%;
    max-width: 240px;
    margin-top: 12px;
  }

  .phonepe-logo .accepted {
    font-size: 18px;
    margin-top: 8px;
  }

  .qr-code {
    max-width: 300px;
  }
}

.checkbox input[type='checkbox'] {
  left: 22px;
  opacity: 0;
}

.as_login_box p {
  color: hsl(204, 25%, 85%);
  font-size: 14px;
}

.modal-body {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 18px;
  padding: 18px 24px;
}

.as_login_data {
  user-select: none;
}

.modal-content .as_btn {
  margin-right: 24px;
  margin-bottom: 18px;
}

.modal {
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2px);
}

.modal-header {
  padding: 24px;
}

.hide-input-container {
  display: none;
}

.otp-container {
  position: relative;
}

.otp-container .send-otp {
  display: block;
}

.articles-popup {
  position: fixed;
  background-color: #17384e;
  top: 200px;
  bottom: 200px;
  right: 200px;
  left: 200px;
  z-index: 1000;
  padding: 0px 24px 16px;
  overflow-y: scroll;
  border-radius: 8px;
  display: none;
}

.articles-popup.show-popup {
  display: block;
}

.articles-popup ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-top: 18px;
}

.articles-popup input {
  margin: 0;
  margin-right: 8px;
  transform: scale(1.25);
  cursor: pointer;
  z-index: 100;
}

.articles-popup h2 {
  margin-top: 0px;
  margin: 0 -24px;
  padding: 16px 16px 8px;
  position: sticky;
  top: 0;
  background-color: #17384e;
  z-index: 200;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.articles-popup ul li {
  margin: 0;
  background-color: unset;
  display: flex;
  align-items: center;
}

.popup-overlay {
  background-color: #000;
  opacity: 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  pointer-events: none;
}

.popup-overlay.active {
  opacity: 0.4;
  pointer-events: all;
}

.items-suggestion {
  position: absolute;
  width: 94%;
  border-radius: 8px;
  height: max-content;
  max-height: 250px;
  overflow-y: scroll;
  z-index: 100;
  top: 90%;
  display: none;
  background-color: #09273c;
}

.items-suggestion.open {
  display: block;
}


.items-suggestion::-webkit-scrollbar {
  width: 10px;
}

.items-suggestion::-webkit-scrollbar-track {
  background: transparent; 
}
 
.items-suggestion::-webkit-scrollbar-thumb {
  background: #38576d;
  border-radius: 8px;
}


.container-dark:hover::-webkit-scrollbar-thumb {
  background: #4A4A4A; 
}

.items-suggestion p {
  cursor: pointer;
  user-select: none;
  padding: 8px 12px;
  margin: 0;
}

.items-container {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.items-container input {
  border: none !important;
  padding-left: 8px;
  min-width: 120px;
  flex-basis: 100%;
  padding-left: 24px;
}

.added-pooja-items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.added-pooja-items.scrollbar {
  overflow-x: scroll;
}

/* width */
.added-pooja-items::-webkit-scrollbar {
  height: 4px;
  position: absolute;
}

/* Track */
.added-pooja-items::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.added-pooja-items::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
  width: 4px;
}

/* Handle on hover */
.added-pooja-items::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.added-pooja-items p {
  background-color: #09273c;
  padding-left: 8px;
  padding-right: 2px;
  border-radius: 4px;
  margin: 0;
  margin-left: 4px;
  cursor: default;
  min-width: max-content;
  display: flex;
  margin-top: 4px;
  display: inline-block;
  margin-bottom: 4px;
}

.added-pooja-items p span {
  cursor: pointer;
  user-select: none;
  padding: 0 4px;
}

.added-pooja-items p span:hover {
  color: #ff7010;
}

.items-suggestion p:hover {
  background-color: hsl(204, 54%, 24%);
}

.added-pooja-items.scrollbar p {
  margin-bottom: 0;
}
