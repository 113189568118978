.as_banner_wrapper {
  background-image: url('../../assets/images/bg/main-section-bg.jpg');
}

.as_banner_img img {
  width: 550px;
}

.mySwiper {
  background-image: url(../../assets/images/bg/main-section-bg.jpg);
}

.swiper-card {
  min-height: 440px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: #ff7010;
}

.card-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.card-text {
  max-width: 480px;
  min-width: 480px;
}

.card-image img {
  box-shadow: 5px 5px 16px rgba(0, 0, 0, 0.9);
  border-radius: 8px;
}

@media (max-width: 1000px) {
  .swiper-card {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 48px;
  }

  .card-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 16px;
  }

  .card-image img {
    min-width: 150px;
  }
}

.card-image {
  display: flex;
  align-items: center;
  margin-left: 5rem;
}

.images-group {
  position: relative;
  width: 100%;
  /* overflow:hidden; */
}

.images-group img {
  width: 100%;
  min-width: 250px;
  max-width: 300px;
  position: absolute;
  z-index: 10;
  border: 0.25rem solid #cfdbe2;
  min-height: 170px;
}

.images-group img:hover {
  z-index: 20;
  transform: scale(1.2);
  transition: all 0.2s;
  border: 0.4rem solid #cfdbe2;
}

.images-group .image-one {
  left: 30%;
  top: 150px;
  z-index: 12;
}

.images-group .image-two {
  left: 15%;
  top: 0;
}

.images-group .image-three {
  right: 0px;
}

@media (max-width: 1000px) {
  .card-image {
    margin-left: 0px;
    margin-top: 5rem;
  }

  .images-group {
    min-height: 360px;
  }

  .images-group img {
    min-width: 180px;
    max-width: 280px;
    min-height: 110px;
  }

  .images-group .image-one {
    left: 50%;
    transform: translateX(-50%);
    top: 150px;
    z-index: 12;
  }

  .images-group .image-two {
    left: 50%;
    transform: translateX(-50%) scale(1.18);
  }

  .images-group .image-three {
    left: 50%;
    transform: translateX(-50%) scale(1.1);
  }

  .images-group img:hover {
    transform: translateX(-50%) scale(1.25);
  }
}

@media (max-width: 1200px) {
  .card-text {
    max-width: 380px;
    min-width: 380px;
  }
}

@media (max-width: 1000px) {
  .card-text {
    max-width: 480px;
    min-width: auto;
  }
}
