.as_logo img {
  width: 250px;
}

@media (max-width: 991px) {
  .as_logo img {
    width: 200px;
  }

  .as_search_wrapper input {
    width: 100px;
  }
}

.as_cart_wrapper {
  cursor: pointer;
  user-select: none;
}

.nav-shop,
.nav-pages {
  display: none !important;
}

@media (min-width: 990px) and (max-width: 1050px) {
  .as_right_info {
    width: 50%;
  }
}

.as_search_widget img {
  width: 25px;
}

.as_infobox {
  cursor: pointer;
}

a {
  cursor: pointer;
}

li {
  list-style: none;
}

.as_info_detail {
  display: none;
}

.as_header_wrapper {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
}

.as_logo {
  padding-top: 0;
  padding-bottom: 0;
  border: none;
}

.as_menu_wrapper {
  border: none;
  justify-content: flex-end;
  align-items: center;
}

.as_menu {
  transition: all 0.2s ease-in-out;
}

.call-us {
  justify-self: flex-start;
  align-self: center;
  display: flex;
  align-items: center;
  margin: 0;
  padding-left: 30px;
}

.logo-section {
  display: flex;
  align-items: center;
  width: 70%;
}

.as_toggle {
  margin-left: auto;
  margin-right: 0;
  margin-left: 16px;
}

.as_search_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  cursor: pointer;
  background-color: var(--dark-color2);
  box-shadow: none !important;
  color: var(--primary-color);
  border-radius: 50%;
  user-select: none;
  padding-top: 0;
  overflow: hidden;
}

.as_search_wrapper input {
  background-color: var(--dark-color2);
  color: var(--primary-color);
  border: none;
  outline: none;
  width: 160px;
}

.as_search_wrapper input::placeholder {
  color: #97b0c1;
}

.as_search {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.search-icon-container {
  display: flex;
  align-items: center;
}

.search-icon-container img {
  width: 100%;
}

@media (max-width: 1200px) {
  .mobile-number,
  .call-us-text {
    display: none;
  }

  .as_header_detail {
    width: 100%;
  }

  .logo-section {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .as_header_wrapper {
    flex-direction: column;
  }

  .logo-section {
    justify-content: space-between;
    width: 100%;
    padding-right: 12px;
  }

  .as_search_wrapper input {
    width: 100%;
  }

  .as_menu {
    width: 70%;
  }

  .nav-services .as_submenu .as_submenu {
    padding-left: 16px !important;
  }

  .as_menu_wrapper {
    justify-content: space-between;
  }
}

.as_menu ul li.as_submenu_li.active:after {
  border-top: none;
  border-bottom: 5px solid #ffffff;
}

.as_menu ul li.as_submenu_li ul li.as_submenu_li.active:after {
  border-top: none;
  border-bottom: 5px solid #ffffff;
}

@media (max-width: 991px) and (min-width: 768px) {
  .as_search_wrapper input {
    width: 100px;
  }
}

.as_menu_wrapper {
  padding: 0 10px;
}

ul.nav-menu {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .as_menu {
    overflow-y: scroll;
  }

  ul.nav-menu {
    flex-direction: column;
    align-items: stretch;
  }

  .as_menu > ul.nav-menu > li:last-child {
    order: -1;
  }
}

.as_menu > ul.nav-menu > li:last-child .as_submenu {
  left: auto;
  right: -10px;
  width: max-content;
  top: 85%;
}

.as_menu > ul.nav-menu > li:last-child .as_submenu::before {
  left: initial;
  right: 26px;
}

.as_menu > ul.nav-menu > li:last-child .as_submenu::after {
  content: ' ';
  width: 100px;
  height: 10px;
  background-color: transparent;
  user-select: none;
  z-index: -10;
  left: initial;
  right: 10px;
}

.username {
  margin: 0;
  margin-left: 12px;
  position: relative;
  font-size: inherit;
}

.username.active {
  color: #fff;
}

.profile-link:hover .username.active {
  color: #ff7010;
}

.username::after {
  content: '';
  width: 6px;
  height: 6px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg) translateY(-50%);
  position: absolute;
  top: 50%;
  right: -10px;
  vertical-align: middle;
}

.as_menu ul li a:hover .username::after {
  border-color: #ff7010;
}

.profile-link {
  display: flex !important;
  align-items: center;
}

@media (min-width: 768px) {
  .username {
    order: -1;
    margin: 0;
    margin-right: 12px;
  }
}

.as_menu > ul > li > a.profile-link:before {
  background-color: transparent;
}

.profile-link + ul li.as_submenu_li:after {
  display: none;
}

@media (max-width: 768px) {
  .as_menu > ul.nav-menu > li:last-child .as_submenu {
    width: 100%;
  }

  .as_menu > ul > li.profile-link-container .as_submenu li a {
    padding-inline: 0;
  }

  .as_menu ul li.profile-link-container::after {
    top: 34px;
  }

  .profile-link-container .as_submenu li:last-child {
    border-bottom-style: dashed;
    border-bottom-color: rgba(207, 219, 226, 0.5);
  }

  .as_menu li.profile-link-container {
    border: none;
  }

  .username {
    font-size: 16px;
  }

  .username::after {
    display: none;
  }
}

.call-us > li {
  display: flex;
  align-items: center;
}
