.order-card {
  border: 1px solid rgb(255 255 255 / 10%);
  display: grid;
  grid-template-columns: 1fr 1fr 100px;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
}

.order-card p {
  margin: 0;
}

.secondary-details {
  font-size: 12px;
  color: #ddd;
}


@media (max-width: 500px) {
  .order-card {
    grid-template-columns: 1fr 1fr;
  }

  .order-card  .order-price {
    margin-top: 12px;
    font-size: 16px;
  }
}

@media (max-width: 358px) {
  .order-card {
    grid-template-columns: 1fr;
  }

  .order-card div + div {
    margin-top: 12px;
  }
}
