.card-header h1 {
  margin: 0;
  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.user-details {
  background-color: #07273c;
  margin-top: 32px;
  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.6);
}
.detail-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 8px;
  min-height: 40px;
}
.detail-row > div {
  display: flex;
  align-items: center;
}

.detail-type {
  font-weight: 600;
}

.edit-detail {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.edit-detail img {
  cursor: pointer;
  display: inline-block;
}

.logout-btn {
  cursor: pointer;
  color: #e5f0f5;
  border: none;
  text-transform: capitalize;
  padding: 7px 20px;
}

.logout-btn:hover {
  color: var(--secondary-color);
}

.save-btn {
  margin-left: 0;
  border-radius: 4px;
  height: auto;
  padding: 4px 16px;
  text-transform: capitalize;
  display: none;
}

.save-btn.editing {
  display: inline-block;
}

.actions-row {
  margin-top: 24px;
}

.detail-value input {
  display: none;
  font-size: 16px;
  padding: 0 16px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.detail-type p {
  margin: 0;
}

.detail-value p {
  margin: 0;
  padding: 0 16px;
  border: 1px solid transparent;
}

@media (max-width: 768px) {
  .detail-value p {
    padding-right: 0;
  }
}

.detail-value.editing p {
  display: none;
}

.detail-value.editing input {
  display: block;
}

.save-btn::after,
.save-btn::before {
  display: none;
}

@media (max-width: 768px) {
  .detail-row > div.edit-detail {
    display: none;
  }
  .detail-row > div.detail-value {
    text-align: right;
    justify-content: flex-end;
  }
  .detail-row.actions-row > div.edit-detail {
    display: flex;
    grid-column-start: 2;
    grid-row-start: 1;
  }

  .detail-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 8px;
    min-height: 40px;
  }
}
