.as_product_img {
  height: 220px;
}

.as_product_img > img {
  height: 100%;
}

.RecentEvents .as_price {
  display: none;
}

.as_product_img ul {
  text-align: center;
}

.as_product_img ul li span {
  margin-left: 0;
  font-size: 18px;
 font-weight: 600;
}

.rating-img {
  width: 80px;
}