.most-popular-icon {
  max-height: 80px;
}

.popular-services-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 16px;
  margin-top: 32px;
}

.as_service_box {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.8);
  border-radius: 4px;
}

.book-appointment .as_select_box select {
  border-right: 18px solid #17384e;
}

.book-appointment .as_select_box select:focus {
  border-color: #17384e;
}