.air-datepicker-body--day-name {
  color: var(--secondary-color);
}

.air-datepicker {
  background-color: #17384e;
  color: var(--primary-color);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.air-datepicker--pointer {
  background-color: #17384e;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.air-datepicker-nav {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.air-datepicker-cell:hover {
  background-color: var(--secondary-color);
}

.air-datepicker-nav--title:hover,
.air-datepicker-nav--action:hover {
  background-color: transparent;
}

.air-datepicker--buttons,
.air-datepicker--time {
  border-color: transparent;
}

.air-datepicker-time--current-hours.-focus-:after,
.air-datepicker-time--current-minutes.-focus-:after {
  background-color: var(--secondary-color);
}

select.form-control option {
  appearance: none;
  padding: 16px;
}
