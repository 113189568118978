.overlay {
  background-color: #000;
  opacity: 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  pointer-events: none;
}

.overlay.active {
  opacity: 0.6;
  pointer-events: all;
}

@media (max-width: 768px) {
  .breadcrumb a {
    font-size: clamp(9px, 3vw, 14px);
  }
}
::-webkit-calendar-picker-indicator {
  filter: invert(0.9);
  
  width: 24px;
  height: 24px;
}

