.as_shopsingle_wrapper {
  background-image: url('../../assets/images/bg/services-bg2.jpg');
}

.swiper-card-small {
  min-height: 100px;
}

.swiper-slide img {
  width: 100%;
  cursor: grab;
}

.mySwiper .swiper-slide img {
  cursor: pointer;
}

.ServicesDetails .mySwiper {
  display: none;
}

.mySwiper2 {
  margin-bottom: 8px;
}
.mySwiper2 .swiper-wrapper {
  height: 260px;
}
.serviceDetailsImg .swiper-wrapper {
  height: 40px;
}

@media (max-width: 768px) {
  .as_product_description h1 {
    margin-top: 16px;
  }
}

.prod_quantity {
  display: none;
}

.starting {
  color: #cfdbe2;
}

.tandc {
  color: #cfdbe2;
  font-size: 14px;
}

