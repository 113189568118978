.modal .modal-dialog.search-box {
  width: 90%;
  max-width: 500px;
}

.search-box .modal-body {
  border-bottom: none;
}

.search-box .modal-header {
  padding-top: 16px;
  padding-bottom: 16px;
}

.service-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

body.modal-open {
  overflow: hidden;
}

[name='remember'] {
  visibility: hidden;
}

.as_signup {
  cursor: pointer;
  user-select: none;
}

.google-login {
  text-align: center;
  margin-top: 16px;
}

.google-login button {
  justify-content: center;
  width: 50%;
}

.google-login svg {
  vertical-align: text-bottom;
}

.error-msg {
  background-color: #301b1e;
  padding: 15px 20px;
  margin: 0 auto;
  margin-bottom: 10px;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  border-color: #f8514966;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #c9d1d9;
  transform: scale(1);
  transition: transform 0.1s ease-in-out;
}

.error-msg.not-show {
  transform: scale(0.8);
  display: none;
}

.error-msg span {
  cursor: pointer;
  color: #f85149;
  font-size: 24px;
  user-select: none;
  vertical-align: middle;
  line-height: 0.9;
}

.error-msg span {
  opacity: 0.7;
}

@media (max-width: 768px) {
  .google-login button {
    justify-content: center;
    width: 80%;
  }
}

.modal-content .as_btn.login-button {
  margin-right: 0;
}

.loading {
  padding: 32px;
}

.modal-header.hidden,
.modal-body.hidden,
.loading.hidden {
  display: none;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #07273c;
  border-right: 5px solid #c9d1d9;
  animation: loading 1s infinite linear;
  margin-inline: auto;
}

.form-group-mobile {
  position: relative;
}

.send-otp {
  position: absolute;
  font-size: 12px;
  top: 50%;
  right: 16px;
  cursor: pointer;
  user-select: none;
  transform: translateY(-50%);
  border-radius: 3px;
  font-weight: 600;
  background-color: #ff7010;
  padding: 2px 12px;
  pointer-events: all;
  display: none;
}

.send-otp.disabled {
  background-color: #e7e7e7;
  color: #999;
  cursor: default;
}

.send-otp.otp-sent {
  background-color: #ebfeec;
  color: #000;
}
.error-message {
  display: none;
  font-size: 12px;
  color: #ff7010;
  position: absolute;
  top: 100%;
  line-height: 1;
}
.error-message.show-error {
  display: block;
}

.tick-icon {
  color: #0bb86b;
  font-family: Muli;
}

span.login-with-otp {
  font-weight: 600;
  cursor: pointer;
  margin-inline: auto;
}

[data-form-type='login-otp'] {
  display: none;
}

.login-with-otp [data-form-type='login-otp'] {
  display: block;
}

.login-with-otp + .form-group-password {
  display: none;
}

.login-with-otp .send-otp {
  display: block;
}

.otp-requested.send-otp::before {
  content: '';
  width: 100%;
  position: absolute;
  background-color: rgba(0, 102, 128, 0.8);
  pointer-events: none;
  left: 0;
  top: -1px;
  bottom: -1px;
  backdrop-filter: blur(1px);
  border-radius: 3px;
}

.otp-requested.send-otp::after {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  opacity: 0.9;
  pointer-events: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #07273c;
  border-right: 2px solid #c9d1d9;
  animation: rotate 1s infinite linear;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
