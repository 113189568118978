.as_team_wrapper {
  background-image: url(../../assets/images/bg/services-bg2.jpg);
}

summary {
  display: list-item;
  user-select: none;
  cursor: pointer;
  padding-left: 8px;
  font-size: 24px;
  margin-bottom: 24px;
}

.as_team_box p {
  font-size: 14px;
}
