.as_contact_section {
  background-image: url(../../assets/images/bg/services-bg2.jpg);
}

.feedback-sent {
  background-color: #1cb71c;
}

.feedback-sent:hover {
  background-color: #1cb71c;
}

.feedback-sent:after {
  border-left-color: #1cb71c;
}


.feedback-sent:before  {
  border-right-color: #1cb71c;
}


.feedback-sent:hover:after {
  border-left-color: #1cb71c;
}

.feedback-sent:hover:before  {
  border-right-color: #1cb71c;
}

.hg-email {
  margin-top: 16px;
}