.Alert {
  background-color: green;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 0;
  padding:16px;
}

.Alert.error {
  background-color: rgb(248, 71, 71);
}

.Alert p {
  margin: 0;
  font-size: 18px;
}